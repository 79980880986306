import React from "react"

import SEO from "../../components/seo"
import Layout from "../../components/layout"
import DefaultLayoutSidebar from "../../components/DefaultLayoutSidebar"
import CustomNavTitle from "../../components/CustomNavTitle"
import SymptomLinkItem from "../../components/SymptomLinkItem"

import { theme } from "../../styles/theme"

import illustration2x from "../../images/illustrations/illustration-transfusion-reaction-symptoms@2x.png"

const TRG = ({ location }) => {
  return (
    <Layout>
      <SEO title="Transfusion Reaction" />

      <CustomNavTitle
        title="Transfusion Reaction"
        destination="/"
        location={{
          state: {
            ...location.state,
            backOverride: "/",
          },
        }}
      />

      <DefaultLayoutSidebar>
        <section className="max-w-content">
          <h2 css={theme.components.title}>
            Please select the most significant symptom from the selection below.
          </h2>

          <ul className="mt-gap-2">
            <li className="mb-gap">
              <SymptomLinkItem
                destination="/transfusion-reaction/immediate-actions"
                letter="A"
                title="Airway"
                symptoms={[
                  "Stridor/increasing wheeze.",
                  "Angio-oedema.",
                  "Anaphylaxis.",
                ]}
              />
            </li>

            <li className="mb-gap">
              <SymptomLinkItem
                destination="/transfusion-reaction/immediate-actions"
                letter="B"
                title="Breathing"
                symptoms={[
                  "Increased breathlessness.",
                  "Increased wheeze.",
                  "Hypoxia/desaturation.",
                ]}
              />
            </li>

            <li className="mb-gap">
              <SymptomLinkItem
                destination="/transfusion-reaction/immediate-actions"
                letter="C"
                title="Circulation"
                symptoms={[
                  "Collapse.",
                  "Hypo/hypertension.",
                  "Tachycardia (new).",
                ]}
              />
            </li>

            <li className="mb-gap">
              <SymptomLinkItem
                destination="/transfusion-reaction/other-symptoms"
                letter="O"
                title="Other Symptoms"
                symptoms={[
                  "Temp ≥39ºC or rise ≥2ºC.",
                  "Temp ≥38ºC and rise ≥1-2ºC.",
                  "Rash/urticaria.",
                  "Chills/rigors.",
                  "New pain (loin/back/abdo/joint).",
                  "Anxiety.",
                  "Nausea.",
                  "A fall in urine output.",
                  "Haemoglobinuria.",
                ]}
              />
            </li>
          </ul>
        </section>
        <aside>
          <img src={illustration2x} alt="Illustration" />
        </aside>
      </DefaultLayoutSidebar>
    </Layout>
  )
}

export default TRG
