import React from "react"
import { Link } from "gatsby"
import { css } from "@emotion/core"

import { theme } from "../styles/theme"

const SymptomLinkItem = ({ destination, title, letter, symptoms }) => {
  return (
    <Link to={destination} css={styles.link}>
      <p css={styles.letter}>{letter}</p>
      <div css={styles.content} className="theme">
        <h4 css={[theme.components.title, styles.title]}>{title}</h4>
        <ul>
          {symptoms.map((item, index) => (
            <li key={index} css={styles.listItem}>
              {item}
            </li>
          ))}
        </ul>
      </div>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="15.421"
        height="25.88"
        viewBox="0 0 15.421 25.88"
        css={{
          flexShrink: 0,
        }}
      >
        <path
          d="M75.381,47.266c-.041-.041-.087-.069-.13-.107L64.9,36.809a2.471,2.471,0,0,0-3.494,0h0a2.471,2.471,0,0,0,0,3.494l8.722,8.722-8.722,8.723a2.471,2.471,0,0,0,0,3.494h0a2.471,2.471,0,0,0,3.494,0L75.251,50.89c.043-.037.09-.066.13-.107a2.5,2.5,0,0,0,0-3.517Z"
          transform="translate(-60.682 -36.085)"
          fill={theme.colours.red}
        />
      </svg>
    </Link>
  )
}

const styles = {
  link: css({
    backgroundColor: theme.colours.lightGrey,
    paddingRight: theme.gap,
    display: "flex",
    alignItems: "center",
    flexDirection: "row",
    borderRadius: theme.borderRadius,
    ":hover,:focus": {
      backgroundColor: theme.colours.midGrey,
    },
  }),
  content: css({
    flexGrow: 1,
    height: "100%",
    borderLeftStyle: "solid",
    borderLeftColor: "#fff",
    borderLeftWidth: 2,
    padding: theme.gap,
  }),
  title: css({
    color: theme.colours.red,
    fontWeight: "bold",
    marginBottom: theme.gapHalf,
  }),
  letter: css({
    fontSize: theme.fzH1,
    fontWeight: "bold",
    color: theme.colours.red,
    padding: theme.gapHalf,
    alignSelf: "flex-start",
  }),
  listItem: css({
    marginBottom: "0.25rem",
    color: theme.colours.black,
  }),
}

export default SymptomLinkItem
